import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaymentSwitchSDK from '@inspira-npm/pg-switch-js';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './Invoice.module.css';
import Error404 from "./error-page/error404";
import Error419 from "./error-page/error419";
import Error from "./error-page/error";
import PaymentSuccess from "./payment/success/payment-success";
import CreditCard from "../components/credit-card";
import DistanceError from "./error-page/distance_error";
var CryptoJS = require("crypto-js");
const generateChecksum = (data) => {
    const hash = CryptoJS.SHA256(data);
    return hash.toString(CryptoJS.enc.Hex);
}
const env = {
    uat: {
        publicKey: 'pk_test_09bf655ff5b950ea4fd95328302bc896e83d7a0725a2adfffbf7e046f62bbd38',
        url: 'https://uat-routen.codestack.ae:3000',
    },
    dev: {
        publicKey: 'pk_test_ca15a5c779c6444d33ed8d0188a15382a0e962c5c876ca44a25edc2d91d2415f',
        url: 'https://dev-routen.codestack.ae:3000',
    },
    prod: {
        publicKey: 'pk_live_118af5fd0d6adba1150508c8ea2564fad3881beb83dc33a73d59f2c8f49e6caa',
        // publicKey: 'pk_test_ab4ae2a2560662a4eed135e81461035be5a1c0d96190650a35c21e4ba48c3488',
        url: 'https://inspirapay.com:3000',
    },
    local: {
        publicKey: '',
        url: 'http://localhost:3000'
    }
}
const environment = 'uat'
const publicKey1 = env[environment].publicKey;
const url = env[environment].url;
const pgSwitchSDK = new PaymentSwitchSDK(
    url,
    publicKey1,
    'API_SECRET_1',
);
const calculateCounter = (date) => {
    // console.log('Expiry Date',date);
    const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;
    let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        nextYear = yyyy + 1,
        dayMonth = (date.getMonth() + 1) + "/" + date.getDate() + "/",
        expiryDate = dayMonth + yyyy;
    today = mm + "/" + dd + "/" + yyyy;
    if (today > expiryDate) {
        expiryDate = dayMonth + nextYear;
    }
    const countDown = new Date(date).getTime();
    const now = new Date().getTime(),
        distance = countDown - now;
    // console.log(now,countDown,distance);
    // document.getElementById("days").innerText = Math.floor(distance / (day)),
    //   document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
    //   document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
    //   document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);
    //do something later when date is reached
    if (distance < 0) {
        return [0, 0, 0, 0];
    }
    return [
        Math.floor(distance / (day)),
        ('0' + Math.floor((distance % (day)) / (hour))).slice(-2),
        ('0' + Math.floor((distance % (hour)) / (minute))).slice(-2),
        ('0' + Math.floor((distance % (minute)) / second)).slice(-2),
    ];
}
const Invoice = () => {
    const { id } = useParams();
    const [paymentLoaded, setPaymentLoaded] = useState(false);
    const [savePaymentLoaded, setSavePaymentLoaded] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [status, setStatus] = useState(false);
    const [counter, setCounter] = useState([-1, -1, -1, -1]);
    const [cards, setCards] = useState([]);
    const [payType, setPayType] = useState('saved_card');
    const [selectedCard, setSelectedCard] = useState({});
    const [publicKey, setPublicKey] = useState({});
    const [error, setError] = useState('');
    const [saveCard, setSaveCard] = useState(true);
    const selectCard = useCallback(async (id, token, bin) => {
        console.log('id: ', id, " token:", token, "bin:", bin);
        setSelectedCard({
            id,
            token,
            bin
        });
        let c = cards;
        c.forEach(card => {
            card.selected = card.id === id;
        });
        setCards([...c]);
    }, [cards]);
    // Device Details Start
    const [location, setLocation] = useState({ status: null, latitude: null, longitude: null });
    const fetchId = useCallback(async () => {
        console.log(location);
        if (id) {
            let paymentElement = document.getElementById('payment-element');
            let buttonElement = document.getElementById('submit');
            let result = await pgSwitchSDK.getInvoiceDetails(id, paymentElement, buttonElement, location.latitude, location.longitude);
            if (result) {

                if (result.code === 419) {
                    setStatus('expired');
                } else if (result.code === 404) {
                    setStatus('notFound');
                } else if (result.code === 208) {
                    setStatus('payment_success');
                } else if (result.code > 210) {
                    setStatus('error');
                } else {
                    setPublicKey(result.invoiceDetails?.public_key);
                    pgSwitchSDK.apiKey = result.invoiceDetails?.public_key;
                    if (result.invoiceDetails.distance <= 500) {
                        setInvoiceData(result);
                        const cards = await pgSwitchSDK.cards({ limit: 100 });
                        if (cards && cards.length > 0) {
                            let otherCard = { ...cards[0] };
                            for (let i = 0; i < cards.length; i++) {
                                if (cards[i].is_default) {
                                    cards[0] = { ...cards[i] };
                                    cards[i] = otherCard;
                                    console.log("Cards id", i, cards[i]);
                                    break;
                                }
                            }
                            cards[0].selected = true;
                            setCards(cards);
                        } else {
                            setCards([]);
                            setPayType('new_card');
                        }
                        const intervalId = setInterval(function () {
                            const r = calculateCounter(new Date(result?.invoiceDetails?.payment_link_expiry_date));
                            if (parseInt(r[0].toString()) === 0 && parseInt(r[1].toString()) === 0 && parseInt(r[2].toString()) === 0 && parseInt(r[3].toString()) === 0) {
                                clearInterval(intervalId);
                                setStatus('expired');
                            }
                            setCounter(r)
                        }, 0);
                    } else {
                        setStatus('distance_error');
                    }
                }

            }
        } else {
            console.log('ID not found.');
        }
    }, [id, location]);
    // useEffect(() => {
    //     if (cards && cards.length > 0) {
    //     }
    // }, [cards])
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ status: true, latitude, longitude });
                },
                (error) => {
                    if (error.code === 1) {
                        setLocation({ status: false, message: 'Please allow location permission for proceed payment.' });
                    }
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };
    useEffect(() => {
        // Get device information
        getLocation();
    }, []);
    // Device Details End

    useEffect(() => {
        if (location.status) {
            setTimeout(async () => {
                fetchId();
            }, 500);
        }
    }, [fetchId, location]);
    useEffect(() => {
        if (status === 'error') {
            document.title = 'Error'
        } else if (status === 'notFound') {
            document.title = 'Invoice not found!'
        } else if (status === 'expired') {
            document.title = 'Invoice is expired!'
        } else if (status === 'payment_success') {
            document.title = 'Payment Successful.';
        } else {
            document.title = 'Invoice Payment.'
        }
    }, [status]);

    useEffect(() => {
        console.log('New load:',payType, saveCard )
        if (payType === 'new_card') {
            if (saveCard) {
                (async () => {
                    let paymentElement = document.getElementById('save-card-element');
                    let buttonElement = document.getElementById('submit');
                    await pgSwitchSDK.initSaveCard(paymentElement, buttonElement, {
                        email: invoiceData.invoiceDetails.email,
                        // order_id: invoiceData?.invoiceDetails?.metadata?.order_id,
                        country_code: invoiceData?.invoiceDetails?.customer?.address?.country_code,
                        // return_url: window.location.hostname,
                        amount_to_capture: parseFloat(invoiceData?.invoiceDetails?.total_amount),
                        amount: parseFloat(invoiceData?.invoiceDetails?.total_amount),
                        currency: invoiceData?.invoiceDetails?.currency,
                        metadata: invoiceData?.invoiceDetails?.metadata,
                        description: 'Invoice: ' + invoiceData?.invoiceDetails.invoice_no,
                        ip: invoiceData?.invoiceDetails?.ip,
                    });
                    setSavePaymentLoaded(true);
                })();
            } else {
                (async () => {
                    // Initilize Payment Gateway start
                    try {
                        const checksum = generateChecksum(
                            `@${invoiceData?.invoiceDetails?.currency}|${invoiceData?.invoiceDetails?.customer?.address?.country_code}|${parseFloat(invoiceData?.invoiceDetails?.total_amount)}|${publicKey}@`,
                        );
                        // make sure this id is configured correctly
                        let paymentElement = document.getElementById('payment-element');
                        let buttonElement = document.getElementById('submit');
                        const result = await pgSwitchSDK.init(paymentElement, buttonElement, {
                            checksum,
                            email: invoiceData.invoiceDetails.email,
                            order_id: invoiceData?.invoiceDetails?.metadata?.order_id,
                            country_code: invoiceData?.invoiceDetails?.customer?.address?.country_code,
                            return_url: `https://${window.location.hostname}`,
                            amount_to_capture: parseFloat(invoiceData?.invoiceDetails?.total_amount),
                            amount: parseFloat(invoiceData?.invoiceDetails?.total_amount),
                            currency: invoiceData?.invoiceDetails?.currency,
                            metadata: invoiceData?.invoiceDetails?.metadata,
                            description: 'Invoice: ' + invoiceData?.invoiceDetails.invoice_no,
                            ip: invoiceData?.invoiceDetails?.ip,
                            invoice_id: id
                        });
                        setPaymentLoaded(true);
                        return result;
                    } catch (e) {
                        console.log('Error during initialization: ', e);
                    }
                    // Initilize Payment Gateway End
                })();
            }
        }
    }, [payType, saveCard]);

    const charge = useCallback(async (selectedCard) => {
        const checksum = generateChecksum(
            `@${invoiceData?.invoiceDetails?.metadata?.order_id}|${invoiceData?.invoiceDetails?.currency}|${invoiceData?.invoiceDetails?.customer?.address?.country_code}|${parseFloat(invoiceData?.invoiceDetails?.total_amount)}|${publicKey}@`
        );
        const result = await pgSwitchSDK.charge({
            checksum,
            ip: '127.0.0.1',
            country_code: invoiceData?.invoiceDetails?.customer?.address?.country_code,
            card_id: selectedCard.id,
            bin: selectedCard.bin,
            email: invoiceData.invoiceDetails.email,
            order_id: invoiceData?.invoiceDetails?.metadata?.order_id,
            amount_to_capture: parseFloat(invoiceData?.invoiceDetails?.total_amount),
            amount: parseFloat(invoiceData?.invoiceDetails?.total_amount),
            currency: invoiceData?.invoiceDetails?.currency,
            subscription: false,
            metadata: invoiceData?.invoiceDetails?.metadata,
            invoice_id: id
        });
        return result;
    }, [id, invoiceData?.invoiceDetails?.currency, invoiceData?.invoiceDetails?.customer?.address?.country_code,
        invoiceData?.invoiceDetails?.email, invoiceData?.invoiceDetails?.metadata, invoiceData?.invoiceDetails?.total_amount,
        publicKey]);


    const handlePayClick = async (e, payment_link_expiry_date, payType, saveCard, charge, selectedCard) => {
        setError('');
        if (new Date(payment_link_expiry_date).getTime() < new Date().getTime()) {
            setStatus('expired');
        } else {
            let result = { status: false };
            if (payType === 'new_card') {
                if (saveCard === false) {
                    result = await pgSwitchSDK.handlePayClick(e);
                    console.log("New card without save result:", result);
                    if (result.status === true) {
                        setStatus('payment_success');
                    }
                } else {
                    const saveCardResult = await pgSwitchSDK.handleSaveCard(e);
                    console.log("New card with save result:", saveCardResult);
                    if (saveCardResult && saveCardResult.success === true) {
                        setSelectedCard({
                            id: saveCardResult.id,
                            token: saveCardResult.token,
                            bin: saveCardResult.bin
                        });
                        result = await charge({
                            id: saveCardResult.id,
                            token: saveCardResult.token,
                            bin: saveCardResult.bin
                        });
                        console.log("Charge result:", result);
                    } else {
                        setError(saveCardResult?.message);
                        return;
                    }
                }
            } else if (payType === 'saved_card') {
                result = await charge(selectedCard);
            }
            if (result && result.status === 'succeeded') {
                setStatus('payment_success');
            } else {
                setError(result?.message);
            }
        }
    };

    return (
        <>
            {
                (() => {
                    if (location.status === true) {
                        switch (status) {
                            case 'notFound':
                                return <Error404 />;
                            case 'error':
                                return <Error />;
                            case 'expired':
                                return <Error419 />;
                            case 'payment_success':
                                return <PaymentSuccess />;
                            case 'distance_error':
                                return <DistanceError />;
                            default:
                                return <div className={styles['invoice-box']}>
                                    <table>
                                        <tbody>
                                            <tr className={styles.top}>
                                                <td colSpan="2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className={styles.title}>
                                                                    {invoiceData?.invoiceDetails?.organization_id?.logo ? <img src={invoiceData?.invoiceDetails?.organization_id?.logo} alt="" style={{ width: "100%", maxWidth: "100px" }} /> : <Skeleton width='100px' />}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        invoiceData?.invoiceDetails?.invoice_no ? <>
                                                                            Invoice #: {invoiceData?.invoiceDetails?.invoice_no}
                                                                            <br />
                                                                        </> : <Skeleton width='250px' />
                                                                    }
                                                                    {
                                                                        invoiceData?.invoiceDetails?.invoice_date ? <>
                                                                            Invoice Date : {format(invoiceData?.invoiceDetails?.invoice_date, 'yyyy-MM-dd HH:mm')}
                                                                            <br />
                                                                        </> : <Skeleton width='250px' />
                                                                    }
                                                                    {
                                                                        invoiceData?.invoiceDetails?.payment_link_expiry_date ?
                                                                            <>Expires on : {format(invoiceData?.invoiceDetails?.payment_link_expiry_date, 'yyyy-MM-dd HH:mm')}
                                                                                <br />
                                                                            </> : <Skeleton width='250px' />
                                                                    }
                                                                    {
                                                                        invoiceData?.invoiceDetails?.payment_link_expiry_date ?
                                                                            <>
                                                                                <div id={`${styles['countdown']}`}>
                                                                                    <ul>
                                                                                        <li>Expires In :</li>
                                                                                        {counter[0] > 0 ? <li><span id="days">{counter[0]}</span>D</li> : null}
                                                                                        {counter[1] > 0 ? <li><span id="hours">{counter[1]}</span> H</li> : null}
                                                                                        {counter[2] > 0 ? <li><span id="minutes">{counter[2]}</span> M</li> : null}
                                                                                        <li><span id="seconds">{counter[3]}</span> S</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </> : <Skeleton width='250px' />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr className={styles.information}>
                                                <td colSpan="2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                </td>
                                                                <td className={styles['title_case']}>
                                                                    {
                                                                        invoiceData?.invoiceDetails?.customer ?
                                                                            <> <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.salutation}&nbsp;</div>
                                                                                <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.first_name}&nbsp;</div>
                                                                                <div className={`${styles['title_case']} d-inline-block`}> {invoiceData?.invoiceDetails?.customer?.last_name}</div><br />
                                                                                {invoiceData?.invoiceDetails?.customer?.email}<br />
                                                                                {invoiceData?.invoiceDetails?.customer?.mobile_number}
                                                                            </> : <Skeleton width='250px' count={3} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr className={styles["heading"]}>
                                                <td>Item</td>
                                                <td>Price</td>
                                            </tr>
                                            {
                                                invoiceData?.invoiceDetails?.item && invoiceData?.invoiceDetails?.item.length > 0 ? <>
                                                    {
                                                        invoiceData?.invoiceDetails?.item.map((item, index) => (
                                                            <tr key={index} className={`${styles['item']} ${index + 1 === invoiceData?.invoiceDetails?.item.length ? styles['last'] : ''}`}>
                                                                <td>{
                                                                    invoiceData.invoiceDetails.is_html ? <>
                                                                        <div dangerouslySetInnerHTML={{ __html: item.item_name }} />
                                                                    </> : item.item_name
                                                                }</td>
                                                                <td>{invoiceData?.invoiceDetails?.currency} {item.amount}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </> :
                                                    <>
                                                        <tr className={styles["item"]}>
                                                            <td><Skeleton /></td>
                                                            <td><Skeleton /></td>
                                                        </tr>
                                                        <tr className={styles["item"]}>
                                                            <td><Skeleton /></td>
                                                            <td><Skeleton /></td>
                                                        </tr>
                                                        <tr className={styles["item"]}>
                                                            <td><Skeleton /></td>
                                                            <td><Skeleton /></td>
                                                        </tr>
                                                    </>
                                            }
                                            <tr className={styles["total"]}>
                                                <td></td>
                                                <td>Total: {invoiceData?.invoiceDetails?.currency} {invoiceData?.invoiceDetails?.total_amount || 0} </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <select className="form-select" value={payType} onChange={(event) => {
                                                        console.log(event.target.value);
                                                        setPayType(event.target.value);
                                                    }}>
                                                        <option value="new_card">Pay with new card</option>
                                                        <option value="saved_card">Pay with saved card</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className={`w-100 py-4`}>
                                                        <div className={`${styles['card-container']} ${payType === 'saved_card' ? 'd-block' : 'd-none'}`}>
                                                            {
                                                                cards && cards.length > 0 ?
                                                                    cards.map((card, i) => {
                                                                        return <React.Fragment key={i}>
                                                                            <CreditCard card={[card]} selectCard={selectCard} />
                                                                        </React.Fragment>
                                                                    }) : null
                                                            }
                                                        </div>
                                                        <div className={`${payType === 'new_card' ? 'd-block' : 'd-none'}`}>
                                                            <div id="payment-element" className={`${saveCard === false ? 'd-block' : 'd-none'}`} ></div>
                                                            <div id="save-card-element" className={`${saveCard === true ? 'd-block' : 'd-none'}`}></div>
                                                        </div>
                                                        <div className={`form-check ${payType === 'new_card' ? 'd-block' : 'd-none'}`}>
                                                            <input
                                                                id="saveCard"
                                                                className={`form-check-input`}
                                                                type="checkbox"
                                                                defaultChecked={saveCard}
                                                                onChange={(event) => {
                                                                    setSaveCard(!saveCard);
                                                                }} />
                                                            <label className="form-check-label" htmlFor="saveCard">
                                                                Save card for future orders
                                                            </label>
                                                        </div>
                                                        <br />
                                                        {
                                                            invoiceData?.invoiceDetails?.terms_condition
                                                                ? <div dangerouslySetInnerHTML={{ __html: invoiceData.invoiceDetails.terms_condition }} />
                                                                : <Skeleton width={'100%'} count={3} />
                                                        }
                                                        <button className={"btn btn-primary mb-2 mt-2"} id="submit" type="button" onClick={(e) => handlePayClick(e, invoiceData?.invoiceDetails?.payment_link_expiry_date, payType, saveCard, charge, selectedCard)}>
                                                            <div className="spinner hidden" id="payment-spinner"></div>
                                                            <span id="payment-button-text">Pay now</span>
                                                        </button>
                                                        <br />
                                                        <span className='text-danger'>
                                                            {error}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div >

                        }
                    } else {
                        return <h5> Please wait...</h5>
                    }
                })()
            }

        </>
    )
}
export default Invoice;